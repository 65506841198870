import {
    AddBox,
    Check,
    DeleteOutline,
    Edit,
    SaveAlt,
    FilterList,
    FirstPage,
    LastPage,
    ChevronRight,
    ChevronLeft,
    Clear,
    Search,
    ArrowDownward,
    Remove,
    ViewColumn,
} from "@material-ui/icons";
import { forwardRef } from "react";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import StorageIcon from "@material-ui/icons/Storage";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import { Button } from "@material-ui/core";
import * as FileSaver from "file-saver";

export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} style={{ color: "var(--red)" }} />
    )),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} style={{ fontSize: "2.5rem" }} />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const tableIconsInputScreen = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => (
        <Check {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
    )),
    Clear: forwardRef((props, ref) => (
        <Clear {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
    )),
    Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} style={{ color: "var(--red)" }} />
    )),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => (
        <Edit {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
    )),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} style={{ fontSize: "2.5rem" }} />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export let customStylesDefault = {
    control: (provided, state) => ({
        ...provided,
        border: "none",
        borderBottom: `1px solid #00B3B3`,
        borderRadius: 0,
        cursor: "text",
        boxShadow: "0 0 0 0 transparent !important",
        minHeight: 0,
        ":hover": {
            border: "none",
            borderBottom: `1px solid #00B3B3`,
            borderRadius: 0,
            boxShadow: "0 0 0 0 transparent !important",
        },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        textAlign: "left",
    }),
    input: (provided, state) => ({
        ...provided,
        textAlign: "left",
        padding: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        textAlign: "left",
        backgroundColor: `var(--select-active-bg-color)`,
        color: `var(--select-active-text-color)`,
    }),
    menuList: (provided, state) => ({
        ...provided,
        color: `var(--select-active-text-color)`,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
};
export let customStylesYear = {
    control: (provided, state) => ({
        ...provided,
        cursor: "text",
        boxShadow: "0 0 0 0 transparent !important",
        minHeight: 0,
        ":hover": {
            boxShadow: "0 0 0 0 transparent !important",
        },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        textAlign: "left",
    }),
    input: (provided, state) => ({
        ...provided,
        textAlign: "left",
        padding: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        textAlign: "left",
        backgroundColor: `var(--select-active-bg-color)`,
        color: `var(--select-active-text-color)`,
    }),
    menuList: (provided, state) => ({
        ...provided,
        color: `var(--select-active-text-color)`,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
};
export const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: `var(--side-drawer-bg-color)`,
        primary25: `var(--app-permission-list-item-color)`,
        primary50: `var(--app-permission-list-item-color)`,
    },
});
export const homeScreenIconStyle = {
    padding: "5px",
    margin: "5px -20px 0px 10px",
};

export const LandingAdmin = [
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/approveReject",
    menuName: "Approve/Reject",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/userManagement",
    menuName: "User Management",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
];

export const QrCalMenus = [
  //{
  //  roleId: 1,
  //  role: "Admin",
  //  menuLink: "/calliberationUpload",
  //  menuName: "Calliberation Certificate Upload",
  //  icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  //},
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/caliberationUpload",
    menuName: "Calibration Certificate",
    icon: <GroupAddIcon style={homeScreenIconStyle} />,
  },
];

export const EnquiryPageMenus = [
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/",
    menuName: "Caliberation Certificate Upload",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/",
    menuName: "Calliberation Certificate",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/",
    menuName: "SCN General Enquiry",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/",
    menuName: "SCN Vehicle Detection",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
];

export const LandingMenus = [
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/fileUpload",
    menuName: "Ecard@EAservices",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 1,
    role: "Admin",
    menuLink: "/admin",
    menuName: "Administration",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
  {
    roleId: 5,
    role: "Supplier",
    menuLink: "/fileUploadExternal",
    menuName: "Ecard@EAservices",
    icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
  },
];

export const MasterRouteLanding = [...LandingAdmin];
export const EnquiryMenu = [...EnquiryPageMenus];
export const LandingMenu = [...LandingMenus];
export const QRMenu = [...QrCalMenus];
export const AdminMenu = [...LandingAdmin];
export const localBaseApiURL = "https://smartqr.si-in.siemens.cloud/api/"; //production
//export const localBaseApiURL = "https://smartqruat.si-in.siemens.cloud/api/";
//export const localBaseApiURL = "https://localhost:44313/api/";

//   export function logoutUser() {
//     var newurl =
//       window.location.protocol +
//       "//" +
//       window.location.host +
//       window.location.pathname;
//     window.history.pushState({ path: newurl }, "", `/`);
//     localStorage.clear();
//     sessionStorage.clear();
//     caches.keys().then((names) => {
//       names.forEach((name) => {
//         caches.delete(name);
//       });
//     });
//     window.location.href = "/";
//   }
export const tableCellPreviousFY = {
    background: `var(--light-red)`,
};
export const tableCellCurrentFY = {
    background: `var(--green-type-1)`,
};
export const tdInputPreviousFY = {
    background: `var(--light-sand--type-1)`,
};
export const tdInputCurrentFY = {
    background: `var(--light-sand)`,
};
export const thInputPreviousFY = {
    background: `var(--light-sand--type-1)`,
    lineHeight: `0.5rem`,
    fontWeight: `bold`,
};
export const thInputCurrentFY = {
    background: `var(--light-sand)`,
    lineHeight: `0.65rem`,
    fontWeight: `bold`,
};
export const getCurrentFiscalYear = () => {
    //get current date
    var today = new Date();
    //get current month
    var curMonth = today.getMonth();

    var fiscalYr = "";
    if (curMonth > 8) {
        //
        var nextYr1 = (today.getFullYear() + 1).toString();
        fiscalYr =
            today.getFullYear().toString() +
            "-" +
            nextYr1.charAt(2) +
            nextYr1.charAt(3);
    } else {
        var nextYr2 = today.getFullYear().toString();
        fiscalYr =
            (today.getFullYear() - 1).toString() +
            "-" +
            nextYr2.charAt(2) +
            nextYr2.charAt(3);
    }
    return fiscalYr;
    //document.write(fiscalYr);
};
export const getRoundUptoXDecimals = (value, decimal) => {
    let factor = Math.pow(10, decimal);
    return Math.round(value * factor + Number.EPSILON) / factor;
};

export const getURLParameter = (sParam) => {
    var sPageURL = Buffer.from(
        window.location.search.substring(1),
        "base64"
    ).toString();
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split("=");
        var value = "";
        for (var j = 0; j < sParameterName.length; j++) {
            if (sParameterName[j] == sParam) {
                for (var k = j + 1; k < sParameterName.length; k++) {
                    value = value + sParameterName[k];
                }
                break;
            }
            //return sParameterName[1];
        }
        if (!value) {
            continue;
        } else {
            break;
        }
    }
    return decodeURI(value);
};
export const renderValueWithThousandSeparator = (value) => {
    return !value
        ? value
        : value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};
export const spocEmail = "mayukh.mukherjee.ext@siemens.com";
export const GetAddButton = () => {
    return <Button className="pr-button--primary ">Add</Button>;
};
export const GetEditButton = () => {
    return <Button className="pr-button--primary">Edit</Button>;
};
export const GetExportButton = () => {
    return <Button className="pr-button--primary">Export</Button>;
};
export const copytoClipboardFn = (fileUrl, handleSnackOpen) => {
    navigator.clipboard.writeText(fileUrl);
    handleSnackOpen("URL copied to Clipboard", "success");
};
export const saveAsTxt = (rowData) => {
    const enggUrl = rowData.fileUrl;
    var blob = new Blob([enggUrl], {
        type: "text/plain;charset=utf-8",
    });
    FileSaver.saveAs(blob, rowData.name + "-" + rowData.workmanType + ".txt");
};